import Cookies from "js-cookie";

export class CookieStorage implements Storage {
    prefix?: string

    constructor(prefix?: string) {
        this.prefix = prefix
    }

    private get _prefix() {
        return this.prefix ? `${this.prefix}-` : ''
    }

    getItem(key: string): string | null {
        return Cookies.get(this._prefix + key) ?? null
    }
    setItem(key: string, value: string) {
        Cookies.set(this._prefix + key, value, { expires: 5000, sameSite: 'strict' })
    }
    get length() {
        return Object.keys(Cookies.get()).length
    }
    removeItem(key: string) {
        Cookies.remove(this._prefix + key)
    }
    clear() {
        Object.keys(Cookies.get()).forEach((key) => Cookies.remove(key))
    }
    key(index: number): string | null {
        return Object.values(Cookies.get())[index] ?? null
    }
}
