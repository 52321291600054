<template>
  <es-button class="!h-7 !w-7" @click="switchTheme">
    <es-icon :icon="theme.isDark ? iWeatherNight : iWeatherSunny" />
  </es-button>
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeUnmount, onMounted, ref} from 'vue'
import {EsButton, EsIcon} from "@esigndoc/ui";
import {iWeatherNight, iWeatherSunny} from "@/entities/icons.ts";
import {CookieStorage} from "@/entities/cookie-storage.ts";
import {$theme as theme} from "@/stores/app-store.ts";

export default defineComponent({
  components: {EsButton, EsIcon},
  setup() {
    onMounted(() => {
      theme.value.activate(new CookieStorage())
    })

    onBeforeUnmount(() => {
      theme.value.destroy()
    })

    const switchTheme = () => {
      theme.value.theme = theme.value.isDark ? 'light' : 'dark'
    }

    return {iWeatherNight, iWeatherSunny, theme, switchTheme}
  }
})
</script>

<style scoped>

</style>
